import * as Sentry from '@sentry/sveltekit';
// eslint-disable-next-line import/no-unresolved
import { env } from '$env/dynamic/public';

if (env?.PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: env?.PUBLIC_SENTRY_DSN,
    environment: env?.PUBLIC_SENTRY_ENV,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1,

    // Optional: Initialize Session Replay:
    integrations: [new Sentry.Replay()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
  });
}

// eslint-disable-next-line import/prefer-default-export
export const handleError = env?.PUBLIC_SENTRY_DSN
  ? Sentry.handleErrorWithSentry()
  // eslint-disable-next-line no-console
  : ({ error }) => console.error(error);
