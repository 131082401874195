import * as client_hooks from '../../src/hooks/hooks.client.js';

export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')];

export const server_loads = [0];

export const dictionary = {
	"/": [3],
	"/category-mapping": [4],
	"/onboarding": [5],
	"/onboarding/success": [6],
	"/organizations/[organization]/analytics": [7],
	"/organizations/[organization]/assets/uploadlinks": [8],
	"/organizations/[organization]/documents": [9],
	"/organizations/[organization]/images": [10],
	"/organizations/[organization]/inventory/products": [11],
	"/organizations/[organization]/inventory/upload": [12],
	"/organizations/[organization]/orders/products": [13],
	"/organizations/[organization]/orders/upload": [14],
	"/organizations/[organization]/pricing/edit": [15],
	"/organizations/[organization]/pricing/status": [16],
	"/organizations/[organization]/pricing/upload": [17],
	"/organizations/[organization]/products/add": [20],
	"/organizations/[organization]/products/catalog": [21],
	"/organizations/[organization]/products/edit": [22],
	"/organizations/[organization]/products/edit/channelSkus": [23],
	"/organizations/[organization]/products/export": [24],
	"/organizations/[organization]/products/export/[template]": [25],
	"/organizations/[organization]/products/export/[template]/view": [26],
	"/organizations/[organization]/products/status": [27],
	"/organizations/[organization]/products/upload": [28],
	"/organizations/[organization]/products/[product]": [18],
	"/organizations/[organization]/products/[product]/documents": [19],
	"/organizations/[organization]/projects": [29],
	"/organizations/[organization]/projects/[project]": [30],
	"/organizations/[organization]/tasks": [31],
	"/organizations/[organization]/tasks/[task]": [32],
	"/publish": [33],
	"/publish/success": [34],
	"/resetpassword": [35],
	"/reviewExport/[jobId]": [36],
	"/settings": [37,[2]],
	"/settings/adminview": [38,[2]],
	"/settings/channels": [39,[2]],
	"/settings/channels/add": [41,[2]],
	"/settings/channels/[channel]": [40,[2]],
	"/settings/inventory": [42,[2]],
	"/settings/notifications": [43,[2]],
	"/settings/orders": [44,[2]],
	"/settings/orgdetails": [45,[2]],
	"/settings/password": [46,[2]],
	"/settings/userdetails": [47,[2]],
	"/settings/warehouses": [48,[2]],
	"/settings/warehouses/add": [50,[2]],
	"/settings/warehouses/[warehouse]": [49,[2]],
	"/unsubscribe": [51]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};